@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        @apply scroll-smooth;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .bg-gradient {
        @apply bg-gradient-to-br from-[#FD6F35] via-[#E53474] to-[#AA2E89];
    }
    .text-gradient {
        @apply bg-clip-text text-transparent bg-gradient-to-br from-[#FD6F35] to-[#AA2E89];
    }
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
}

.container {
    @apply px-5 sm:px-[50px];
}

.input {
    @apply block w-full rounded-[3px] border-0 py-[7px] text-base sm:text-xl text-primary shadow-sm ring-1 ring-inset ring-[#505050]/50 placeholder:text-primary/30 placeholder:text-base sm:placeholder:text-lg placeholder:leading-6 focus:ring-2 focus:ring-inset focus:ring-[#505050]/90 leading-6 sm:leading-7;
}

.label {
    @apply block text-sm font-medium leading-6 text-primary;
}

.scrollbar::-webkit-scrollbar {
    width: 2px !important;
}
 
.scrollbar::-webkit-scrollbar-thumb {
    background: #d4d4d8;
    border-radius: 5px;
}
 
.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #a1a1aa;
}

.line-shadow {
    border:none;
    height: 20px;
    width: 100%;
    height: 50px;
    margin-top: 0;
    border-bottom: 1px solid #d5d5d5;
    box-shadow: 0 8px 10px -10px #6f6f6f60;
    margin: -50px auto 10px; 
}

.amarlist ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}
  
.amarlist li {
    padding-left: 1em;
    text-indent: -1em;
    margin: 16px 0px;
}
  
.amarlist li:before {
    content: "-";
    padding-right: 10px;
}

@keyframes slide {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.logos {
    overflow: hidden;
    white-space: nowrap;
    justify-content: center;
}

.logos-slide {
    direction: row;
    gap: 120px;
    animation: 25s slide infinite linear;
}

.logos-slide img:nth-child(-n+3) {
    height: 40px;
}

.logos-slide img:nth-child(4) {
    height: 30px;
    align-self: center;
}

.logos-slide-register {
    direction: row;
    gap: 60px;
    animation: 25s slide infinite linear;
}

.logos-slide-register img {
    height: 25px;
}

.logos-mobile-slide {
    direction: row;
    gap: 10px;
    animation: 25s slide infinite linear;
}

.logos-mobile-slide img {
    height: 18px;
}

.bulleted {
    text-indent: -0.6em;
    padding-left: 1em;
}

.bulleted::before {
    content: "• ";
    color: #0057D8;
    font-size: 1em;
    font-weight: bold;
}

@layer base {
    @font-face {
        font-family: 'GIP';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('./fonts/GIP-Regular.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url('./fonts/GIP-RegularItalic.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url('./fonts/GIP-Thin.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: italic;
        font-weight: 300;
        font-display: swap;
        src: url('./fonts/GIP-ThinItalic.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: normal;
        font-weight: 200;
        font-display: swap;
        src: url('./fonts/GIP-Light.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: italic;
        font-weight: 200;
        font-display: swap;
        src: url('./fonts/GIP-LightItalic.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: normal;
        font-weight: 100;
        font-display: swap;
        src: url('./fonts/GIP-UltraLight.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: italic;
        font-weight: 100;
        font-display: swap;
        src: url('./fonts/GIP-UltraLightItalic.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('./fonts/GIP-Medium.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: italic;
        font-weight: 500;
        font-display: swap;
        src: url('./fonts/GIP-MediumItalic.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url('./fonts/GIP-SemiBold.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: italic;
        font-weight: 600;
        font-display: swap;
        src: url('./fonts/GIP-SemiBoldItalic.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('./fonts/GIP-Bold.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url('./fonts/GIP-Bold.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: normal;
        font-weight: 800;
        font-display: swap;
        src: url('./fonts/GIP-ExtraBold.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: italic;
        font-weight: 800;
        font-display: swap;
        src: url('./fonts/GIP-ExtraBoldItalic.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url('./fonts/GIP-Black.otf') format('opentype');
    }
    @font-face {
        font-family: 'GIP';
        font-style: italic;
        font-weight: 900;
        font-display: swap;
        src: url('./fonts/GIP-BlackItalic.otf') format('opentype');
    }
    html {
        font-family: 'GIP';
        font-optical-sizing: auto;
        font-weight: 500;
        font-style: normal;
        font-variation-settings: "slnt" 0;
    }
}